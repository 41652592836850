/* eslint-disable no-console */

import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  ContactFormDataInput,
  FormContactWebexQuery,
  useSendContactFormMutation,
} from '@/api';
import {
  FormSectionContact,
  FormSectionProps,
} from '@/components/form/contactForm/FormSectionContact';
import { FormAddress } from '@/components/form/FormAddress';

export const FormContact = ({ data }: { data: FormContactWebexQuery }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendForm] = useSendContactFormMutation({
    onCompleted() {
      setSuccess(true);
    },
  });

  const onSubmit: SubmitHandler<ContactFormDataInput> = async (formData) => {
    console.log('onSubmit called');
    setLoading(true);
    console.log('Form data:', formData);
    try {
      await sendForm({ variables: { data: formData } });
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  const formData = data?.webexIntegration?.data?.attributes;

  const formProps: FormSectionProps = {
    anchor: formData?.slug || undefined,
    onSubmit: onSubmit,
    loading: loading,
    success: success,
    setLoading: () => setLoading(!loading),
    setSuccess: () => setSuccess(!success),
  };

  return (
    <section>
      <FormAddress />
      <div className="bg-tg-bg py-20 lg:py-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-screen-lg text-center lg:mb-20">
                <span className="text-lg font-semibold mb-2 block text-primary">
                  Kontaktujte nás
                </span>
                <h2 className="text-3xl sm:text-4xl mb-4 font-bold text-dark md:text-[40px]">
                  Neváhejte, vyzkoušejte!
                </h2>
                <div className="flex flex-wrap justify-center gap-y-6">
                  <p className="text-base text-body-color">
                    Přesvědčte se sami, jak umíme zlepšit i vaši spolupráci.
                    Nejlepším důkazem je osobní zkušenost. Naše řešení si můžete
                    vyzkoušet kdykoliv a z pohodlí své kanceláře.
                  </p>
                  <p className="text-base text-body-color">
                    Vedle základních konceptů (v zasedačce, kdekoliv a kdykoliv)
                    pro vás rádi připravíme i další testovací nasazení na míru.
                  </p>
                  <p className="text-base text-body-color">
                    Dejte nám o sobě vědět a naši specialisté vás budou obratem
                    kontaktovat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <FormSectionContact {...formProps} />
          </div>
        </div>
      </div>
    </section>
  );
};
