import { Fragment } from 'react';

import {
  AboutUsContentFragment,
  HomeContentFragment,
  ManufacturerPartnerContentFragment,
  ProductFragment,
  ReferenceContentFragment,
  SocialResponsibilityContentFragment,
} from '@/api';
import DynamicImage from '@/components/shared/DynamicImage';
import DynamicText from '@/components/shared/DynamicText';
import getCdlPublicId from '@/hooks/useCdlPublicId';

type Props = {
  content?:
    | AboutUsContentFragment
    | HomeContentFragment
    | ReferenceContentFragment
    | ManufacturerPartnerContentFragment
    | ProductFragment
    | SocialResponsibilityContentFragment
    | null;
};
const DynamicContent = ({ content }: Props) => {
  return (
    <Fragment>
      {content?.content?.map((content, index) => {
        switch (content?.__typename) {
          case 'ComponentSharedTextContent':
            return <DynamicText richText={content?.text} parentIndex={index} />;
          case 'ComponentUiImage':
            return (
              <DynamicImage
                image={{
                  imgSrc: getCdlPublicId(
                    content?.img_desktop?.data?.attributes?.url,
                  ),
                  imgSrcMobile: getCdlPublicId(
                    content?.img_mobile?.data?.attributes?.url,
                  ),
                  alt: content?.alt || '',
                  blurHash:
                    content?.img_desktop?.data?.attributes?.blurhash ||
                    '12312312',
                }}
              />
            );
        }
      })}
    </Fragment>
  );
};

export default DynamicContent;
