import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { imageType } from '@/types/imageType';

const DynamicImage = ({ image }: { image: imageType }) => {
  return (
    <div className="mt-5 h-[480px] w-full">
      <PictureWithBlurHash
        fullScreen
        imgSrc={image.imgSrc}
        imgSrcMobile={image.imgSrcMobile}
        isPathMobile={!!image.imgSrcMobile}
        alt={image.alt}
        blurHash={image.blurHash}
        classNameWrapper="w-full h-[480px]"
      />
    </div>
  );
};

export default DynamicImage;
