interface ProjectConfig {
  cloudinaryProjectName: string;
  projectVideoCompanyLink: string;
}

const projectConfig: ProjectConfig = {
  cloudinaryProjectName: 'collapo/web-cms',
  projectVideoCompanyLink: '/kontakt',
};

const projectVideoLogoPublicId = `${projectConfig.cloudinaryProjectName}/global/logo-video`;

export { projectConfig, projectVideoLogoPublicId };
