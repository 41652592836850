import clsx from 'clsx';
import { ReactNode } from 'react';
import { Blurhash } from 'react-blurhash';

export interface BlurHashImageProps {
  blurHash?: string;
  children?: ReactNode;
  className?: string;
  classNameSize?: string;
  enabledDuration?: boolean;
}

const HashBlurImage = ({
  blurHash = 'LABWiA-;00D%~qx]4nof00D%~q?I',
  children,
  className,
  classNameSize,
  enabledDuration = false,
}: BlurHashImageProps) => {
  return (
    <div
      className={clsx(
        'inset-none absolute z-[1] block transition-all motion-reduce:transition-none',
        enabledDuration ? 'duration-700' : 'duration-0',
        className,
      )}
    >
      {children}
      <Blurhash
        hash={blurHash}
        punch={1}
        className={clsx(
          '!inset-none !absolute z-[1] !block',
          classNameSize ? `${classNameSize} !h-auto !w-full` : '!size-full',
        )}
      />
    </div>
  );
};

export default HashBlurImage;
