import RichText, { RichTextProps } from '@/components/shared/RichText';

const DynamicText = ({
  richText,
  parentIndex,
}: {
  richText: RichTextProps[];
  parentIndex: number;
}) => {
  return (
    <div className="space-y-5">
      <RichText
        isProducePage
        items={richText}
        parentSection="dynamicText"
        parentIndex={parentIndex}
      />
    </div>
  );
};

export default DynamicText;
