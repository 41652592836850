/* eslint-disable no-console */

import { GetStaticPropsContext, InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterDocument,
  FooterQuery,
  FooterQueryVariables,
  FormContactWebexDocument,
  FormContactWebexQuery,
  FormContactWebexQueryVariables,
  HomeContentMainDocument,
  HomeContentMainQuery,
  HomeContentMainQueryVariables,
  HomeHeroImageDocument,
  HomeHeroImageQuery,
  HomeHeroImageQueryVariables,
  HomeHeroVideoDocument,
  HomeHeroVideoQuery,
  HomeHeroVideoQueryVariables,
  HomeSeoDocument,
  HomeSeoQuery,
  HomeSeoQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { Homepage } from '@/views/homepage';

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const client = initializeApollo();
  const { locale } = context;

  console.log(context);
  console.log(locale);

  // prefetch
  await client.query({ query: HomeSeoDocument });
  await client.query({ query: HomeHeroVideoDocument });
  await client.query({ query: HomeHeroImageDocument });
  await client.query({ query: HomeContentMainDocument });
  await client.query({ query: FooterDocument });
  await client.query({ query: NavigationDocument });
  await client.query({ query: FormContactWebexDocument });

  const { data: seoData } = await client.query<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >({ query: HomeSeoDocument });

  const { data: heroVideoData } = await client.query<
    HomeHeroVideoQuery,
    HomeHeroVideoQueryVariables
  >({ query: HomeHeroVideoDocument });

  const { data: heroImageData } = await client.query<
    HomeHeroImageQuery,
    HomeHeroImageQueryVariables
  >({ query: HomeHeroImageDocument });

  const { data: contentData } = await client.query<
    HomeContentMainQuery,
    HomeContentMainQueryVariables
  >({ query: HomeContentMainDocument, variables: { locale: locale || 'cs' } });

  const { data: formData } = await client.query<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >({
    query: FormContactWebexDocument,
  });

  const { data: footerData } = await client.query<
    FooterQuery,
    FooterQueryVariables
  >({ query: FooterDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({ query: NavigationDocument });

  return addApolloState(client, {
    props: {
      seoData,
      heroVideoData,
      heroImageData,
      contentData,
      formData,
      footerData,
      headerData,
    },
  });
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  seoData,
  heroVideoData,
  heroImageData,
  contentData,
  formData,
  footerData,
  headerData,
}) => (
  <Homepage
    seo={seoData?.home?.data?.attributes?.seo}
    heroVideo={heroVideoData.home?.data?.attributes?.heroVideoSection}
    heroImage={heroImageData.home?.data?.attributes?.heroImageSection}
    content={contentData?.home?.data?.attributes}
    formWebexData={formData}
    footerData={footerData}
    headerData={headerData}
  />
);

export default Home;
