import {
  FooterQuery,
  FormContactWebexQuery,
  HeroImageSectionFragment,
  HeroVideoSectionFragment,
  HomeContentFragment,
  NavigationQuery,
  SeoFragment,
} from '@/api';
import { FormContact } from '@/components/form/contactForm/FormContact';
import { Layout } from '@/components/layout/Layout';
import { Seo } from '@/components/Seo';
import DynamicContent from '@/components/shared/DynamicContent';

type Props = {
  seo?: SeoFragment | null;
  heroVideo?: HeroVideoSectionFragment | null;
  heroImage?: HeroImageSectionFragment | null;
  content?: HomeContentFragment | null;
  formWebexData: FormContactWebexQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Homepage = ({
  seo,
  heroVideo,
  heroImage,
  content,
  formWebexData,
  footerData,
  headerData,
}: Props) => {
  return (
    <Layout footerData={footerData} headerData={headerData}>
      <Seo seo={seo} />
      <DynamicContent content={content} />
      {/*{image && (*/}
      {/*  <section>*/}
      {/*    <div className="bg-tg-bg py-10 lg:py-[60px]">*/}
      {/*      <div className="group relative h-[400px] w-full">*/}
      {/*        <PictureWithBlurHash*/}
      {/*          fullScreen*/}
      {/*          alt={image.alt}*/}
      {/*          imgSrc={image.img_desktop.data?.attributes?.url || ''}*/}
      {/*          blurHash={*/}
      {/*            image.img_desktop?.data?.attributes?.blurhash ||*/}
      {/*            'L35rfH^$xZS%Q*t:tni^M{Zyxwxv'*/}
      {/*          }*/}
      {/*          classNameWrapper="h-[370px] w-full lg:h-[451px]"*/}
      {/*          classNameImage="transition-all duration-300 group-hover:scale-105"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </section>*/}
      {/*)}*/}
      {formWebexData && <FormContact data={formWebexData} />}
    </Layout>
  );
};
